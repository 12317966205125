const monthNames = [
  "janv.",
  "févr.",
  "mars",
  "avr.",
  "mai",
  "juin",
  "juill.",
  "août",
  "sept.",
  "oct.",
  "nov.",
  "déc.",
]

export const dateEnToFr = (dateString) => {
  const regex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
  const matches = regex.exec(dateString);
  if (matches) {
      var arrDate = dateString.split("-");
      var day = arrDate[2];
      var month = arrDate[1];
      var year = arrDate[0];
      return day + "/" + month + "/" + year;
  }
  return null
};

export const dateInText = (dateString) => {
  const regex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
  const matches = regex.exec(dateString);
  if (matches) {
    return (
      monthNames[parseInt(dateString.split("-")[1]) - 1] + " " + dateString.split("-")[0]
    );
  }
  return null;
}

export const customYears = () =>{
  const currentYear = new Date().getFullYear() + 1;
  const startYear = 1988;
  const yearsArray = [];

  for(let year = startYear; year <= currentYear; year++){
    yearsArray.push(year.toString())
  }

  return yearsArray.reverse();
}
